import { Stack } from '@mui/material';
import { useLocations, Location } from 'api/locations';
import { useSiteTemplates } from 'api/site';
import { linkHelper } from 'linkHelper';
import { FormProvider, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { SelectOption } from 'component/new_design/base/forms/Select';
import { TextField } from 'component/new_design/base/forms/TextField';
import { SelectField } from 'component/new_design/base/forms/SelectField';
import { Flag } from 'component/new_design/base/Flag';
import { NoteCard } from 'component/new_design/base/NoteCard';
import { useEffect } from 'react';
import { createStaticPaginatedListState } from 'component/hooks/usePaginatedListState';

export interface SiteDetailsFormValue {
  readonly siteName: string;
  readonly location: string | undefined;
  readonly templateId: string | undefined;
  readonly isRestrictedLocation: boolean;
}

interface Props {
  readonly onComplete: (data: SiteDetailsFormValue) => void;
  readonly onFormDirty?: (state: boolean) => void;
  readonly formId: string;
  readonly siteTemplateId?: string;
}

export const SiteDetailsStep = ({ onComplete, onFormDirty, formId, siteTemplateId }: Props) => {
  const { t } = useTranslation();

  const methods = useForm({
    defaultValues: {
      siteName: '',
      location: undefined,
      templateId: siteTemplateId,
    },
    mode: 'onChange',
  });

  useEffect(() => {
    onFormDirty?.(methods.formState.isDirty);
  }, [methods.formState.isDirty]);

  const { data: siteTemplatesData, isLoading } = useSiteTemplates(createStaticPaginatedListState());

  const { data } = useLocations();
  const locations = data?.data.result.locations ?? [];
  const enterpriseLocations = data?.data.result.restricted_locations ?? [];
  const enterpriseLocationIds = enterpriseLocations.map(location => String(location.id));

  const createLocationOption = ({ id, location }: Location) => {
    const locationSplit = location.split(' - ');
    return {
      value: String(id),
      label: (
        <Stack direction="row" alignItems="center" gap={2}>
          <Flag code={locationSplit[0]} size="s" />
          <div>{location}</div>
        </Stack>
      ),
    };
  };

  let locationOptions: SelectOption<string>[] = [];

  if (enterpriseLocations?.length) {
    locationOptions = [
      { type: 'subheader', label: t('enterprise_locations') },
      ...enterpriseLocations.map(createLocationOption),
      { type: 'subheader', label: t('standard_locations') },
      ...locations.map(createLocationOption),
    ];
  } else {
    locationOptions = locations.map(createLocationOption) ?? [];
  }

  const templateOptions: SelectOption<string>[] =
    siteTemplatesData?.data.result?.site_templates?.map(template => ({
      value: template.template_id ?? '',
      label: template.name ?? '',
    })) ?? [];

  const noTemplatesAvailable = !isLoading && !templateOptions.length;

  const handleSubmit = (values: Omit<SiteDetailsFormValue, 'isRestrictedLocation'>) => {
    onComplete({
      ...values,
      isRestrictedLocation: enterpriseLocationIds.includes(values.location!),
    });
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(handleSubmit)} id={formId}>
        <Stack gap={4} maxWidth="320px">
          <TextField
            name="siteName"
            label={t('site_name_label')}
            placeholder={t('site_name_label_placeholder')}
            fullWidth
            rules={{
              required: true,
            }}
            helperText={t('site_name_label_description')}
          />
          <SelectField
            name="location"
            label={t('select_location')}
            fullWidth
            enableEmptyOption
            emptyOptionLabel={t('choose_location')}
            options={locationOptions}
            rules={{ required: true }}
          />
          <SelectField
            name="templateId"
            label={t('build_from_template')}
            fullWidth
            options={templateOptions}
            enableEmptyOption
            emptyOptionLabel={t('select_template')}
            loading={isLoading}
            disabled={noTemplatesAvailable}
          />
          {!!noTemplatesAvailable && (
            <NoteCard>
              <Trans
                i18nKey="create_template_site_create_description"
                components={{
                  siteTemplatesLink: <Link to={linkHelper.sites.templates.getLink()} />,
                }}
              />
            </NoteCard>
          )}
        </Stack>
      </form>
    </FormProvider>
  );
};
